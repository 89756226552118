.form-characteristic-input {
  min-width: 48px; // existing style to ensure minimum width

  // Remove arrows from number inputs for Webkit browsers like Chrome, Safari, etc.
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Remove arrows from number inputs for Firefox
  &[type='number'] {
    -moz-appearance: textfield;
  }

  // Remove arrows from number inputs for IE11 and Microsoft Edge
  &[type='number']::-ms-clear,
  &[type='number']::-ms-reveal {
    display: none;
  }
}
